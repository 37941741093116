<section class="bg100 h100vh" [formGroup]="jobsForm">
  <div class="content container-row-response">
    <div class="left-container">
      <article class="container-row-only pt30px">
        <div class="container-column mb30px">
          <span class="program cursor-pointer" routerLink="/view-programs"
            >Programs ›</span
          >
          <span class="title-set">Program</span>
        </div>
      </article>
      <article class="container-column">
        <div class="card-basic">
          <div class="container-row-only">
            <div class="container-flex w70">
              <span class="title-params">{{
                jobsForm.controls.program_name.value
              }}</span>
            </div>

            <div class="btn-end">
              <span class="text-ver">Version: </span>
              <mat-form-field appearance="outline" class="select-version">
                <mat-select formControlName="program_versions">
                  <mat-option
                    *ngFor="let prog_version of prog_versions"
                    [value]="prog_version.id"
                  >
                    {{ prog_version.version.replace("v", "") }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <hr class="custom-divider divider" />
          <div class="container-row mb20px">
            <div class="black07 w20 name-right">
              Sample Set<sup class="text-red">*</sup>
            </div>
            <div class="field-content">
              <div class="w100">
                <!-- TODO static url should be dynamic with user permissions -->
                <app-custom-mat-select-search
                  [matSelect]="matSelect"
                  [showChipList]="true"
                  formControlName="setId"
                  [selectOptions]="{
                    url: '/api/sample_sets',
                    label: 'set_name',
                    value: 'id',
                  }"
                  [formControl]="jobsForm.controls.setId"
                ></app-custom-mat-select-search>
              </div>
              <!-- <h4 class="empty-message" *ngIf="!jobsForm.controls.setId.value && this.commonService.csvRecords.value.length > 0"> Select Sample set to apply the program</h4> -->
            </div>

            <div class="black05 pl15pt7">
              Name of sample set in imported file
            </div>
          </div>
          <div class="container-row">
            <div class="title-select">Expected outputs</div>
          </div>
          <div class="container-row mt20px">
            <div class="black07 w20 name-right">Concentration Table</div>
            <div class="field-content mb30px">
              <div class="black05">
                The output file consisting of a table with a row for each
                metabolite in the Pyxis menu and a column for each Sample Name
                in the Sample Set. For each metabolite detected in the raw file,
                the absolute concentration (in uM) will be in the cell.
              </div>
            </div>
          </div>
          <div class="container-row">
            <div class="content-step"></div>
            <div>
              <button
                mat-flat-button
                color="accent"
                class="text-white"
                type="button"
                (click)="runProgram()"
                [disabled]="
                  !jobsForm.controls.setId.value || commonService.clicked
                "
              >
                Run Program
                <span class="img-icon"
                  ><img src="../../../assets/images/arrow-r.svg"
                /></span>
              </button>
            </div>
          </div>
        </div>
      </article>
    </div>
    <!-- <div class="rightCol sideCol">
      <article class="container-column">
        <div class="card-basic position-fix-response">
          <div class="center-center">
            <img class="icon" src="../../../../../../assets/icons/info-new.svg" alt="">
            <span class="title-right">About this program</span><br>
          </div>
          <div class="span-text">
            <span class="text">
              “Absolute Quantitative Metabolomics” applies the Pyxis algorithm for imputing absolute concentration from data
              acquired in accordance with the Pyxis candles method. The program will read all raw files in the sample
              set, and for all metabolites in the specified concentration range of the Pyxis menu provide both an
              absolute concentration and a prediction error.
            </span>
          </div>
        </div>
      </article>
    </div> -->
  </div>
</section>
