import { SelectionModel } from "@angular/cdk/collections"
import { HttpClient } from "@angular/common/http"
import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { LocalStorageService } from "ngx-localstorage"
import { CommonService } from "src/app/core/services/commonservice.service"

@Component({
  selector: "app-targeted-absolute-dialog",
  templateUrl: "./targeted-absolute-dialog.component.html",
  styleUrls: ["./targeted-absolute-dialog.component.scss"],
})
export class TargetedAbsoluteDialogComponent implements OnInit {
  displayedColumns: string[] = ["select", "version"]
  listVersions: any[] = []
  selection = new SelectionModel<any>(true, [])
  currentOrg: string
  programViewers: any
  initialSelectionState = new Map<string, boolean>()

  constructor(
    public http: HttpClient,
    public commonService: CommonService,
    public dialogRef: MatDialogRef<TargetedAbsoluteDialogComponent>,
    private localStorageService: LocalStorageService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.currentOrg = this.localStorageService.get("clickedRowId") as string
    this.programViewers = data.allData
  }

  ngOnInit() {
    this.transformData()
  }

  transformData() {
    if (this.programViewers) {
      // Transform data to a simple array of version objects
      this.listVersions = Object.keys(this.programViewers).map((version) => {
        // Check if the version has the currentOrg property and if it's true
        const isSelected = this.programViewers[version] === true

        return {
          version: version,
          selected: isSelected,
        }
      })

      // Sort versions
      this.sortByVersion()

      // Clear previous selection
      this.selection.clear()

      // Set initial selection based on current state
      this.listVersions.forEach((row) => {
        if (row.selected) {
          this.selection.select(row)
        }
        // Store initial state for comparison when saving
        this.initialSelectionState.set(row.version, row.selected)
      })
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.listVersions.length
    return numSelected === numRows
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear()
      return
    }

    this.selection.select(...this.listVersions)
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${row.version}`
  }

  createRequestBody() {
    // Only include versions that have changed state
    return {
      items: this.listVersions
        .filter(
          (row) =>
            this.initialSelectionState.get(row.version) !==
            this.selection.isSelected(row),
        )
        .map((row) => {
          return {
            enabled: this.selection.isSelected(row),
            org_id: this.currentOrg,
            program_version: row.version,
          }
        }),
    }
  }

  onApplyClick() {
    const requestBody = this.createRequestBody()

    // Only make API call if there are changes
    if (requestBody.items.length > 0) {
      this.http.put("/api/programs/viewers", requestBody).subscribe({
        next: () => {
          this.commonService.updateVersion()
          this.dialogRef.close()
        },
        error: (error) => {
          console.error("Error updating program versions:", error)
        },
      })
    } else {
      // Close dialog if no changes
      this.dialogRef.close()
    }
  }

  sortByVersion(): void {
    this.listVersions.sort((a, b) => {
      return this.compareVersions(a.version, b.version)
    })
  }

  compareVersions(versionA: string, versionB: string): number {
    const numA = versionA.replace("v", "").split(".").map(Number)
    const numB = versionB.replace("v", "").split(".").map(Number)

    for (let i = 0; i < Math.max(numA.length, numB.length); i++) {
      if ((numA[i] || 0) < (numB[i] || 0)) return -1
      if ((numA[i] || 0) > (numB[i] || 0)) return 1
    }
    return 0
  }
}
