import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"

import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
// import { NgxDropzoneModule } from 'ngx-dropzone';
// import { NgxCsvParserModule } from 'ngx-csv-parser';
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { BnNgIdleService } from "bn-ng-idle"
import { CoreModule } from "./core/core.module"
import { MaterialModule } from "./material.module"

// import { CoreModule } from './core/core.module';
// import { ProgramsModule } from './programs/programs.module';

// import { CsvTableComponent } from './csv-table/csv-table.component';
// // import { CsvParserComponent } from './csv-parser/csv-parser.component';
// import { InitService } from './initializers/init-service';
// import { WorkerserviceService } from './services/workerservice.service';
// import { AuthService } from './services/auth.service';
import { HomeModule } from "../app/features/feature/pages/home/home.module"

import { AuthGuard } from "@auth0/auth0-angular"
import { NgxCsvParserModule } from "ngx-csv-parser"
import { NgxDropzoneModule } from "ngx-dropzone"
import { FeatureModule } from "./features/feature/feature.module"

import { OverlayModule } from "@angular/cdk/overlay"
import { PortalModule } from "@angular/cdk/portal"
import { ScrollingModule } from "@angular/cdk/scrolling"
import { CookieService } from "ngx-cookie-service"
import { LocalStorageDirective } from "ngx-localstorage"
import { ToastrModule } from "ngx-toastr"
import { environment } from "../environments/environment"
import { UserGuard } from "./core/guards/user.guard"
import { WebWorkerInterceptor } from "./core/interceptors/web-worker.interceptor"
import { HttpWebWorkerClientModule } from "./http-web-worker-client/http-web-worker-client.module"

import { AuthHttpInterceptor, AuthModule } from "@auth0/auth0-angular"
import { OrganizationsModule } from "./features/feature/pages/organizations/organizations.module"

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule,
    NgxDropzoneModule,
    NgxCsvParserModule,
    HomeModule,
    CoreModule,
    FeatureModule,
    ScrollingModule,
    LocalStorageDirective,
    OverlayModule,
    PortalModule,
    OrganizationsModule,
    HttpWebWorkerClientModule,
    ToastrModule.forRoot({
      timeOut: 4000,
      positionClass: "toast-top-right",
    }),
    OrganizationsModule,
    HttpClientModule,
    AuthModule.forRoot({
      domain: environment.auth_domain,
      clientId: environment.auth_client_id,
      issuer: environment.auth_issuer,
      authorizationParams: {
        audience: environment.auth_audience,
        redirect_uri: window.location.origin,
      },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    BnNgIdleService,
    { provide: HTTP_INTERCEPTORS, useClass: WebWorkerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    AuthGuard,
    UserGuard,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
