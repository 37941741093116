<app-current-organization></app-current-organization>
<section *ngIf="!isLoading" class="bg-sample pt30px">
  <div class="content container-row-response">
    <article class="container-row-only card-basic mb20px">
      <div class="first-column">
        <div class="title-model">Model</div>
        <div class="title-set-card">
          <!-- {{program.name}} -->
          Pyxis - Polar Absolute Quantitation
        </div>
        <div class="text-container">
          “Pyxis - Polar Absolute Quantitation” applies the Pyxis algorithm for
          concentration from data acquired in accordance with the Pyxis candles
          method. The program will read all raw files in the sample set, and for
          all metabolites in the specified concentration range of the Pyxis menu
          provide both an absolute concentration and a prediction error.
        </div>
      </div>
      <div class="second-column vertical-border">
        <div class="container-row-program space-between">
          <div class="column">
            <!-- <ng-container *ngFor="let program of programContainer"> -->
            <div class="vers-info">
              Included Versions ({{ activePrograms }} of
              {{ sortedVersions?.length }}):
            </div>
            <!-- </ng-container> -->
          </div>
          <div class="column">
            <div class="btn-end">
              <button
                type="button"
                mat-stroked-button
                color="accent"
                (click)="openManageDialog()"
              >
                Manage
              </button>
            </div>
          </div>
        </div>
        <div class="container-row-program">
          <div class="container-chips">
            <!-- <div class="center-center gap8" *ngFor="let version of program.programs">
                             <div class="chips">
                                 <span class="chips-span chips-inside">{{version.program_version.replace('v',
                                     '')}}</span>
                             </div> 
                         </div>                                -->
            <div *ngFor="let version of sortedVersions">
              <div class="chips">
                <span
                  class="chips-span chips-inside"
                  [ngClass]="{
                    'true-color': isVersionActive(version),
                    'false-color': !isVersionActive(version),
                  }"
                >
                  {{ version }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="vertical-border p15">
                <mat-slide-toggle>
                    Active
                </mat-slide-toggle>
            </div> -->
    </article>
  </div>
</section>
<div *ngIf="isLoading">
  <div class="container-spinner">
    <div class="spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
