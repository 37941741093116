import { HttpClient } from "@angular/common/http"
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { MatTableDataSource } from "@angular/material/table"
import { Router } from "@angular/router"
import { Subscription } from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "../../../../../core/services/commonservice.service"
import { WorkerserviceService } from "../../../../../core/services/workerservice.service"
interface ColumnNames {
  [key: string]: any
}

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
})
export class SidenavComponent implements OnChanges {
  private sidenavClosedSubscription!: Subscription
  displayedColumns: any[] = []
  dataSource = new MatTableDataSource(<any>[])
  @Input() element: any
  isLoading: boolean = true

  finalData = []

  columnNames: ColumnNames = {}

  id: any
  today = new Date()
  link: any
  sampleset_name: any

  page = 1
  pending = false
  pageSize = 50

  created_date: any
  ownerId: any
  email: any
  samplesetId: any

  statusSet: any
  sampleNameTitle: any
  constructor(
    public workerService: WorkerserviceService,
    private router: Router,
    public commonService: CommonService,
    private http: HttpClient,
    public dialog: MatDialog,
    public authService: AuthService,
  ) {}

  listSamplesUrl(samplesetId: string) {
    let base = `/api/samples/sample_set/${samplesetId}`

    if (this.authService.permissions?.includes("read:platform_sample_sets")) {
      base += "/platform"
    } else if (
      this.authService.permissions?.includes("read:organization_sample_sets")
    ) {
      base += "/organization"
    }

    return base
  }
  ngOnInit(): void {
    this.commonService.sidenavClosed.subscribe((isClosed) => {
      if (isClosed) {
        this.clearData()
      }
    })

    this.element.subscribe((res: any) => {
      if (res) {
        this.commonService.searchedSetID.next(res)
        this.samplesetId = res.id
        this.created_date = res.created_date
        this.email = res.user.email
        this.http
          .get(this.listSamplesUrl(this.samplesetId))
          .subscribe((response: any) => {
            if (response) {
              this.commonService.isSidenavOpen = false
            }
            this.sampleset_name = Object.values(response[0].attributes)[1]
            this.ownerId = response[0]["owner_id"]
            this.finalData = response
            this.sampleNameTitle = response[0].attributes
            this.statusSet = response.status
            response.map((el: any) => {
              response[0].attributes.map((col: any) => {
                if (col.name !== "Sample Type") {
                  this.columnNames[col.name] = this.transform(col.name)
                }
                //   this.columnNames[col.name] = this.transform(col.name);
              })
              this.displayedColumns = []
              response[0].attributes.map((sample: any) => {
                // this.displayedColumns.push(sample.name);
                if (sample.name !== "Sample Type") {
                  this.displayedColumns.push(sample.name)
                }
              })
            })
            this.dataSource.data = response.slice(0, this.pageSize)
          })
      }
    })
  }

  clearData() {
    this.dataSource.data = []
  }

  transform(element: string) {
    // @ts-ignore
    const result = element
      .replace(/[0-9]/g, "")
      .replaceAll("_", " ")
      .replace(/([A-Z])/g, " $1")
      .trim()
    let finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  getNestedAttributes(attributes: Array<any>, column: string) {
    let content
    attributes.map((el) => {
      if (el.name === column) {
        content = el.content
      }
    })
    return content
  }

  getPrograms() {
    this.router.navigateByUrl(`view-programs`)
    this.commonService.sampleSetId.next(this.samplesetId)
  }

  ngOnChanges(changes: SimpleChanges) {
    this.init()
    this.dataSource.data = this.finalData.slice(0, this.page * this.pageSize)
  }

  private init() {
    if (this.dataSource) {
      return
    }
    // this.dataSource = new MatTableDataSource([]);

    // this.offset = this.viewport.renderedRangeStream.pipe(
    //   map(() => -this.viewport.getOffsetToRenderedContentStart())
    // );
  }

  nextBatch(event: any) {
    const buffer = 25
    const end = this.dataSource.data.length
    if (end - event < buffer && this.pending === false) {
      if (this.dataSource.data.length < this.finalData.length) {
        this.pending = true
        this.page = Math.floor(end / this.pageSize)
        this.dataSource.data = [
          ...this.dataSource.data,
          ...this.finalData.slice(
            this.page * this.pageSize,
            (this.page + 1) * this.pageSize,
          ),
        ]
        setTimeout(() => {
          this.pending = false
        }, 300)
      }
    }
  }
}
