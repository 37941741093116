import { HttpClient } from "@angular/common/http"
import { Component, OnInit } from "@angular/core"
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { CommonService } from "src/app/core/services/commonservice.service"

import { SnackbarCreateOrgComponent } from "../snackbar-create-org/snackbar-create-org.component"
import { SnackbarOrgsComponent } from "../snackbar-orgs/snackbar-orgs.component"

@Component({
  selector: "app-create-org-dialog",
  templateUrl: "./create-org-dialog.component.html",
  styleUrls: ["./create-org-dialog.component.scss"],
})
export class CreateOrgDialogComponent implements OnInit {
  addNewOrgForm!: FormGroup
  selectCtrl = new FormControl()
  selectedFile!: File | null
  selectedOrganization: any
  logoUploadSuccess: boolean = false
  logoBase64: string | null = null
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  creatingOrg: boolean = false

  constructor(
    private formBuilder: FormBuilder,
    public snackbar: MatSnackBar,
    public http: HttpClient,
    public commonService: CommonService,
    private dialog: MatDialog,
  ) {}
  ngOnInit(): void {
    this.buildForm()
  }
  private buildForm() {
    this.addNewOrgForm = this.formBuilder.group({
      info: [""],
      name: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z0-9 .,!?'()|_&-]+")],
      ],
      logo: this.selectedFile,
    })
  }
  onFileSelected($event: Event) {
    const input = $event.target as HTMLInputElement
    if (input.files && input.files.length) {
      const file = input.files.item(0)
      if (file) {
        if (file.size > 500 * 1024) {
          this.snackbar.openFromComponent(SnackbarOrgsComponent, {
            data: "File size must not exceed 500KB.",
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: [
              "my-custom-snackbar-error",
              "mat-toolbar",
              "mat-primary",
            ],
            duration: 5000,
          })
          input.value = ""
          return
        }
        const reader = new FileReader()
        reader.onload = (e: any) => {
          this.logoBase64 = e.target.result
          this.logoUploadSuccess = true
        }
        reader.onerror = (error) => console.error("Error reading file:", error)
        reader.readAsDataURL(file)
      } else {
        console.log("No file selected or file selection was cancelled.")
        this.logoUploadSuccess = false
      }
    }
  }

  addNewOrg() {
    this.creatingOrg = true
    this.selectedOrganization = this.selectCtrl.value
    const logoToSend = this.logoBase64 ? this.logoBase64 : ""
    let body = {
      info: this.addNewOrgForm.controls["info"].value.replace(
        /[^A-Za-z0-9. ]/g,
        "-",
      ),
      logo: logoToSend,
      name: this.addNewOrgForm.controls["name"].value,
    }

    this.http.post("/api/organizations", body).subscribe(
      (res) => {
        if (res) {
          let snackBarRef = this.snackbar.openFromComponent(
            SnackbarCreateOrgComponent,
            {
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition,
              panelClass: [
                "my-custom-snackbar-error",
                "mat-toolbar",
                "mat-primary",
              ],
              duration: 5000,
            },
          )
          snackBarRef.afterDismissed().subscribe((res) => {
            if (res.dismissedByAction) {
            }
          })
        }
        this.commonService.announceOrgsUpdate()
        this.dialog.closeAll()
        this.creatingOrg = false
      },
      (error: any) => {
        this.creatingOrg = false
        console.error(error)
      },
    )
  }
}
