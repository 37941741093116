import { HttpClient } from "@angular/common/http"
import { Component, Inject, OnInit } from "@angular/core"
import { FormBuilder, FormGroup } from "@angular/forms"
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { CustomSnackbarComponent } from "../custom-snackbar/custom-snackbar.component"
import { DeleteUserDialogComponent } from "../delete-user-dialog/delete-user-dialog.component"

@Component({
  selector: "app-edit-user-dialog",
  templateUrl: "./edit-user-dialog.component.html",
  styleUrls: ["./edit-user-dialog.component.scss"],
})
export class EditUserDialogComponent implements OnInit {
  editUserForm!: FormGroup
  data: any
  name: any
  role: any
  email: any
  filterUser: any[] = []
  family_name: any
  currentUser: any
  id: any

  roles = [{ value: "Regular User", key: 0 }]
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  roleCognito: any

  constructor(
    public dialog: MatDialog,
    public authService: AuthService,
    private formBuilder: FormBuilder,
    public snackbar: MatSnackBar,
    public http: HttpClient,
    public commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public dataDialog: any,
  ) {
    this.id = dataDialog.id
    if (this.authService.role == 1) {
      this.roles = [
        { value: "Regular User", key: 0 },
        { value: "Organization Admin", key: 1 },
      ]
    } else if (this.authService.role == 2) {
      this.roles = [
        { value: "Regular User", key: 0 },
        { value: "Organization Admin", key: 1 },
        { value: "Platform Admin", key: 2 },
      ]
    }

    if (dataDialog.role == 0) {
      this.role = "Regular User"
    } else if (dataDialog.role == 1) {
      this.role = "Organization Admin"
    } else if (dataDialog.role == 2) {
      this.role = "Platform Admin"
    }
  }

  ngOnInit() {
    console.log(this.dataDialog)

    this.editUserForm = this.formBuilder.group({
      first_name: [this.dataDialog.givenName || ""],
      middle_name: [this.dataDialog.middleName || ""],
      last_name: [this.dataDialog.familyName || ""],
      bucket: [this.dataDialog.bucket || ""],
      id: [this.dataDialog.id],
      role: [this.dataDialog.role || ""],
    })
    if (this.role === "Platform Admin") {
      this.editUserForm.get("role")?.disable()
    }
  }

  updateUserUrl() {
    let base = "/api/users"
    if (this.authService.permissions?.includes("update:platform_users")) {
      base += "/platform"
    } else if (
      this.authService.permissions?.includes("update:organization_users")
    ) {
      base += "/organization"
    }
    return base
  }

  editUser() {
    let user_info = this.editUserForm.value
    user_info.role = this.roles
      .filter((x) => user_info.role == x.value)
      .map((x) => x.key)[0]
    console.log("Updating user:", user_info)
    if (this.roleCognito == 1) {
      user_info.role = 0
    } else if (user_info.role == undefined) {
      user_info.role = this.roles
        .filter((x) => this.role == x.value)
        .map((x) => x.key)[0]
    }
    this.http.put(this.updateUserUrl(), user_info).subscribe((res: any) => {
      if (res) {
        this.commonService.announceUserUpdate()
      }
      this.snackbar.openFromComponent(CustomSnackbarComponent, {
        data: { name: "User updated successfully" },
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ["my-custom-snackbar-error", "mat-toolbar", "mat-primary"],
        duration: 10000,
      })
    })

    this.dialog.closeAll()
    //   setTimeout(()=> {
    //     console.log(res)
    // const message = 'The system does not allow to edit user'
    // this.snackbar.openFromComponent(CustomSnackbarUserComponent, {
    //   data: { text: message },
    //   horizontalPosition: this.horizontalPosition,
    //   verticalPosition: this.verticalPosition,
    //   panelClass: ['custom-snackbar-error', 'mat-toolbar', 'mat-primary'],
    //   duration: 10000
    // });
    // }), 1000
  }
  // });

  // }
  openDeleteDialog(id: string, email: string) {
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      width: "380px",
      // height: '490px',
      hasBackdrop: true,
      data: { id: this.id, email: email },
    })
    dialogRef.afterClosed().subscribe((res: any) => {
      console.log(res)
    })
  }
}
