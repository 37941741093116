import { SelectionModel } from "@angular/cdk/collections"
import { HttpClient } from "@angular/common/http"
import { Component, ElementRef, Inject, ViewChild } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { MatStepper } from "@angular/material/stepper"
import { MatTableDataSource } from "@angular/material/table"
import { Router } from "@angular/router"
import { ToastrService } from "ngx-toastr"
import { ToggleService } from "src/app/shared/services/toggle.service"
import { CommonService } from "../../../../../core/services/commonservice.service"
import { WorkerserviceService } from "../../../../../core/services/workerservice.service"
import { DialogData, Elements } from "../../../models/text-import.interface"

@Component({
  selector: "app-text-import-dialog",
  templateUrl: "./text-import-dialog.component.html",
  styleUrls: ["./text-import-dialog.component.scss"],
})
export class TextImportDialogComponent {
  @ViewChild("stepper")
  stepper: any = ElementRef
  @ViewChild("modValue")
  modValue: any = ElementRef
  active = false
  isLinear = true
  displayedColumns: any[] = [
    "select",
    "importingFileColumnName",
    "dataType",
    "pyxisColumn",
  ]
  options = ["Text", "Link"]
  samples: any[] = []
  dataSource = new MatTableDataSource<any>([])
  selection = new SelectionModel<any>(true, [])
  lastAddedFile: any
  set_name: any
  sampleIdlink: any
  email: any
  created_date: any
  allFiles: any = []
  columnNames: any = {}
  id: string = ""
  isLoading = false
  isFailed: boolean = false
  statusClass: any = "not-active"
  isEditable = false
  isButtonClicked = false

  rawFile: any

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"

  sampleSetConfigForm = new FormGroup({
    sampleFileField: new FormControl("", Validators.required),
    sampleNameField: new FormControl("", Validators.required),
    setId: new FormControl(this.id),
    set_nameField: new FormControl("", Validators.required),
  })
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<TextImportDialogComponent>,
    public workerService: WorkerserviceService,
    public commonService: CommonService,
    public toggleService: ToggleService,
    private http: HttpClient,
    public snackbar: MatSnackBar,

    public dialog: MatDialog,
    private toastr: ToastrService,
  ) {}

  getName(lastAddedFile: any) {
    let name
    if (Array.isArray(lastAddedFile)) {
      name =
        lastAddedFile[0].name.indexOf(".csv") > -1
          ? lastAddedFile[0].name.split(".csv")[0]
          : lastAddedFile[0].name
    } else {
      name =
        lastAddedFile.name.indexOf(".csv") > -1
          ? lastAddedFile.name.split(".csv")[0]
          : lastAddedFile.name
    }
    return name
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length
    const numRows = this.dataSource.data.length
    this.dataSource.data.map((row) => {
      if (this.columnNames[row.importCol]) {
        delete this.columnNames[row.importCol]
      } else {
        this.columnNames[row.importCol] = row.name
      }
    })
    return numSelected === numRows
  }

  toggleAllRows() {
    if (this.selection.selected.length === 0) {
      this.selection.select(...this.dataSource.data)
    } else if (
      this.selection.selected.length > 0 &&
      this.selection.selected.length < this.dataSource.data.length
    ) {
      this.selection.select(...this.dataSource.data)
    } else if (this.selection.selected.length === this.dataSource.data.length) {
      this.selection.clear()
    }
  }

  select(row: any) {
    this.selection.toggle(row)
    if (this.columnNames[row.importCol]) {
      delete this.columnNames[row.importCol]
    } else {
      this.columnNames[row.importCol] = row.name
    }
  }

  onSelect(event: any) {
    this.allFiles.push(event.addedFiles[0])
    this.active = true
    this.statusClass = "active"
  }

  onClick(stepper: MatStepper) {
    this.isButtonClicked = true
    this.active = true
    let formData = new FormData()
    formData.append("file", this.allFiles[0])
    this.http
      .post(`/api/sample_sets/metadata_file`, formData)
      .subscribe((response: any) => {
        let elements: Elements[] = []
        Object.keys(response[0].samples[0].attributes).map((key) => {
          elements.push({
            importCol: response[0].samples[0].attributes[key].name,
            type: "Text",
            name: response[0].samples[0].attributes[key].name,
          })
        })
        // console.log('++++ error import', response)
        this.dataSource.data = elements
        this.lastAddedFile = this.allFiles[this.allFiles.length - 1]
        this.id = response[0].id
        this.set_name = response[0].set_name
        this.sampleIdlink = response[0].samples[0].attributes[2].content
        this.email = response[0].email
        // this.rawFile = response[0].samples[0].attributes[2].content
        this.created_date = response[0].created_date
        this.samples.push(...response[0].samples)
        this.dataSource.data.map((row) => {
          if (this.columnNames[row.importCol]) {
            delete this.columnNames[row.importCol]
          } else {
            this.columnNames[row.importCol] = row.name
          }
        })
        stepper.next()
        this.toggleAllRows()
        // }
      })
  }
  success() {
    this.sampleSetConfigForm.controls.setId.setValue(this.id)
    let body: any = this.sampleSetConfigForm.value

    Object.keys(body).forEach((key) => {
      body[key] = body[key].replaceAll("  ", " ")
    })
    this.dialogRef.close(body)
  }

  onRemove(event: any) {
    this.allFiles = []
    this.active = false
    this.isButtonClicked = false
  }

  // changeSelection(event: any, el: any) {
  //   el.type = event.value
  //   if (this.columnNames[el.importCol]) {
  //     this.columnNames[el.importCol].type = event.value;
  //   }
  // }
}
