import { HttpClient } from "@angular/common/http"
import { Component, OnDestroy, OnInit } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { LocalStorageService } from "ngx-localstorage"
import { Subscription } from "rxjs"
import { CommonService } from "src/app/core/services/commonservice.service"
import { TargetedAbsoluteDialogComponent } from "../../../components/targeted-absolute-dialog/targeted-absolute-dialog.component"

@Component({
  selector: "app-programs-setup",
  templateUrl: "./programs-setup.component.html",
  styleUrls: ["./programs-setup.component.scss"],
})
export class ProgramsSetupComponent implements OnInit, OnDestroy {
  programViewers: any
  sortedVersions: string[] = []
  currentOrg: string
  activePrograms: number = 0
  isLoading: boolean = true
  versionUpdateSubscription!: Subscription

  constructor(
    public dialog: MatDialog,
    public http: HttpClient,
    public commonService: CommonService,
    private localStorageService: LocalStorageService,
  ) {
    this.currentOrg = this.localStorageService.get("clickedRowId") as string
  }

  ngOnInit() {
    this.getProgramsInfo()
    this.versionUpdateSubscription =
      this.commonService.versionUpdate$.subscribe(() => {
        this.getProgramsInfo()
      })
  }

  getProgramsInfo() {
    this.isLoading = true
    this.http.get("/api/programs/viewers").subscribe({
      next: (res: any) => {
        this.programViewers = res
        this.sortedVersions = this.extractAndSortVersions(res)
        this.countActiveVersions()
        this.isLoading = false
      },
      error: (error) => {
        console.error("Error fetching program viewers:", error)
        this.isLoading = false
      },
    })
  }

  extractAndSortVersions(data: any): string[] {
    if (!data) return []

    // Extract unique versions
    const versions = Object.keys(data)

    // Sort versions
    return versions.sort((a, b) => {
      const versionA = a
        .slice(1)
        .split(".")
        .map((num) => parseInt(num, 10))
      const versionB = b
        .slice(1)
        .split(".")
        .map((num) => parseInt(num, 10))

      for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
        if ((versionA[i] || 0) !== (versionB[i] || 0)) {
          return (versionA[i] || 0) - (versionB[i] || 0)
        }
      }
      return 0
    })
  }

  countActiveVersions() {
    this.activePrograms = 0

    if (this.programViewers) {
      Object.keys(this.programViewers).forEach((version) => {
        if (this.programViewers[version] === true) {
          this.activePrograms++
        }
      })
    }
  }

  openManageDialog() {
    const dialogRef = this.dialog.open(TargetedAbsoluteDialogComponent, {
      width: "640px",
      height: "600px",
      hasBackdrop: true,
      data: {
        allData: this.programViewers,
      },
    })

    dialogRef.afterClosed().subscribe(() => {
      // The versionUpdate$ subscription will handle refreshing the data
    })
  }

  isVersionActive(version: string): boolean {
    return this.programViewers && this.programViewers[version] === true
  }

  ngOnDestroy(): void {
    if (this.versionUpdateSubscription) {
      this.versionUpdateSubscription.unsubscribe()
    }
  }
}
