import { HttpClient } from "@angular/common/http"
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { MatSelect } from "@angular/material/select"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { ActivatedRoute, Router } from "@angular/router"
import { BehaviorSubject, Subject } from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "../../../../../core/services/commonservice.service"
import { WorkerserviceService } from "../../../../../core/services/workerservice.service"
import { CustomSnackbarJobComponent } from "../../../components/custom-snackbar-job/custom-snackbar-job.component"

export interface PeriodicElement {
  description: string
  inputsAttributes: string
}

export interface OutputElement {
  description: string
  inputsAttributes: string
}

export interface ProgramVersionElement {
  id: string
  program_version: string
  program_name: string
}

export interface ProgVersion {
  id: string
  version: string
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    description:
      "is the collection of samples in a study for which the researcher desires to the have concentration imputed together and  collated in a single “Concentration Table” ",
    inputsAttributes: "Sample Set Name",
  },
  {
    description:
      "is the identification for each individual sample (i.e., MS injection) in the Sample Set",
    inputsAttributes: "Sample Name",
  },
  {
    description:
      "a link specifying the location of the raw file for each Sample Name in the Sample Set (e.g. path in S3 bucket)",
    inputsAttributes: "Raw File Path",
  },
]

const outputInfo: OutputElement[] = [
  {
    description:
      "The output file consisting of a table with a row for each metabolite in the Pyxis menu and a column for each Sample Name in the Sample Set. For each metabolite detected in the raw file, the absolute concentration (in uM) will be in the cell.",
    inputsAttributes: "Concentration Table",
  },
]

@Component({
  selector: "app-program-parameters",
  templateUrl: "./program-parameters.component.html",
  styleUrls: ["./program-parameters.component.scss"],
})
export class ProgramParametersComponent implements OnInit, OnDestroy {
  id: any
  samples: any
  sampleSelectName: any
  sampleSet = new BehaviorSubject(<any>"")
  program = new BehaviorSubject(<any>{})

  allSets: any
  filteredOptions: any
  prog_versions: any = []
  lastVersion: any

  displayedColumnsIntup: string[] = ["inputsAttributes", "description"]
  dataSourceInput = ELEMENT_DATA

  displayedColumnsOutput: string[] = ["inputsAttributes", "description"]
  dataSourceOutput = outputInfo

  jobsForm = new FormGroup({
    setId: new FormControl(""),
    sampleName: new FormControl(""),
    rawFilePath: new FormControl(""),
    concentrationTable: new FormControl(""),
    program_name: new FormControl(""),
    program_versions: new FormControl({}),
    autoControl: new FormControl(""),
    columnNames: new FormControl([]),
    program_id: new FormControl(""),
  })
  state: any
  sampleSetID: any
  selectedProgram: any
  totalSize: any
  offset = 0
  pageSize = 50
  page = 0

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  tryAgainTimeOut: any
  unsubscriber = new Subject()

  @ViewChild(MatSelect, { static: false }) matSelect!: MatSelect

  constructor(
    private route: ActivatedRoute,
    public authService: AuthService,
    private router: Router,
    private workerService: WorkerserviceService,
    private http: HttpClient,
    public snackbar: MatSnackBar,
    public commonService: CommonService,
  ) {
    this.state = this.router.getCurrentNavigation()?.extras.state
    if (this.state) {
      this.selectedProgram = this.state
      // if (this.commonService.sampleSetId.value) {
      //   this.jobsForm.controls.setId.setValue(this.commonService.sampleSetId.value || '');
      // } else {
      //     this.commonService.csvRecords.pipe(takeUntil(this.unsubscriber)).subscribe((res: any) => {

      //       if (res.length > 0) {
      //         this.jobsForm.controls.setId.setValue(res[0].id)
      //         this.unsubscriber.next(true);
      //       }
      //     })
      // }
      this.jobsForm.controls.program_name.setValue(this.selectedProgram.name)

      this.selectedProgram.programs.forEach((prog: ProgramVersionElement) => {
        this.prog_versions.push({
          id: prog.id.toString(),
          version: prog.program_version.toString(),
        })
      })

      this.prog_versions.sort((a: ProgVersion, b: ProgVersion) => {
        const versionA = a.version
          .slice(1)
          .split(".")
          .map((num: string) => parseInt(num, 10))
        const versionB = b.version
          .slice(1)
          .split(".")
          .map((num: string) => parseInt(num, 10))

        for (let i = 0; i < versionA.length; i++) {
          if (versionA[i] !== versionB[i]) {
            return versionA[i] - versionB[i]
          }
        }
        return 0
      })

      this.jobsForm.controls.program_versions.setValue(
        this.prog_versions.slice(-1)[0].id,
      )
    }
  }

  ngOnInit(): void {
    this.commonService.clicked = false
    this.jobsForm.valueChanges.subscribe((res: any) => {})
    this.id = this.route.snapshot.paramMap.get("id")
  }

  runProgram() {
    let body = {
      program_id: this.jobsForm.controls.program_versions.value,
      parameters: {},
      //{
      //   sampleName: this.jobsForm.controls.sampleName.value,
      //   rawFilePath: this.jobsForm.controls.rawFilePath.value,
      //   concentrationTable: this.jobsForm.controls.concentrationTable.value,
      //},
      sample_set_id: String(this.jobsForm.controls.setId.value),
    }
    let url = "/api/jobs"
    if (this.authService.permissions?.includes("create:platform_job")) {
      url += "/platform"
    } else if (
      this.authService.permissions?.includes("create:organization_job")
    ) {
      url += "/organization"
    }
    this.http.post(url, body).subscribe((res: any) => {
      this.snackbar.openFromComponent(CustomSnackbarJobComponent, {
        data: { name: "Job has been created", id: res.id },
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ["my-custom-snackbar-error", "mat-toolbar", "mat-primary"],
        duration: 10000,
        //test
      })

      if (res) {
        this.commonService.clicked = !this.commonService.clicked
        this.router.navigateByUrl(`job-results/`, res)
      }
    })
    this.commonService.clicked = true
  }

  ngOnDestroy(): void {
    this.commonService.searchControl.setValue("")
  }
}
